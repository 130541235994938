.boxesWrapper {
  display: flex;
  margin-top: 224px;
  justify-content: space-evenly;
  margin-bottom: 100px;
  flex-wrap: wrap;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
}

.stableCoinBox {
  background: linear-gradient(180deg, #FFFFFF 0%, #E0E4F4 100%);
  box-shadow: 0px 0px 40px #CCE3F9;
  border-radius: 32px;
  max-width: 480px;
  text-align: center;
  margin: 15px;
  @media screen and (min-width: 500px) and (max-width: 1020px) {
    width: calc(100% - 170px);
    max-width: calc(100% - 170px);
  }
}

.stableCoinBoxWrapper {
  margin-top: -120px;
}

.iconGroup {
  text-align: center;
  @media screen and (max-width: 500px) {
    width: 80%;
  }
}

// .imgWrapper {
//   margin: 0 auto;
// }

.stableTitle {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  padding: 32px 32px 16px 32px;
  text-align: justify;
}

.stableSubText {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: rgb(1, 10, 30, 0.64);
  padding: 0 32px 40px 32px;
  text-align: justify;
}

.rightBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 15px;
  max-width: 480px;
  // width: 45%;
  @media screen and (min-width: 500px) and (max-width: 1020px) {
    width: calc(100% - 170px);
    max-width: calc(100% - 170px);
  }
}

.prepaidBox {
  min-height: 324px;
  background: linear-gradient(180deg, #FFFFFF 0%, #E0E4F4 100%);
  box-shadow: 0px 0px 40px #CCE3F9;
  border-radius: 32px;
  display: flex;
  margin-bottom: 32px;
  padding-top: 16px;
  padding-right: 16px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    padding: 24px;
  }
}

.prepaidImg {
  border-radius: 32px;
  width: 400px;
}

.prepaidImgWrapper {
  display: flex;
  align-items: flex-end;
}

.prepaidTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.prepaidTitle {
  margin-bottom: 16px;
}

.prepaidSubText {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: rgb(1, 10, 30, 0.64);
}

.giftBox {
  // max-width: 480px;
  min-height: 324px;

  background: linear-gradient(180deg, #FFFFFF 0%, #E0E4F4 100%);
  box-shadow: 0px 0px 40px #CCE3F9;
  border-radius: 32px;
  display: flex;
}

.giftBoxWrapper {
  display: flex;
  align-items: center;
  padding: 32px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    padding: 24px;
  }
}

.giftTitle {
  margin-bottom: 16px;
}

.giftSubText {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: rgb(1, 10, 30, 0.64);
}
