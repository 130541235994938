.headerWrapper {
  position: fixed;
  width: 100%;
}

.innerWrapper {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 80px 0 80px;
  > a {
    text-decoration: none;
  }
  @media screen and (max-width: 800px) {
    margin: 16px 40px 0 40px;
  }
  @media screen and (max-width: 450px) {
    margin: 16px 20px 0 20px;
    align-items: flex-start;
  }
}

.title {
  @media screen and (max-width: 450px) {
    width: 100px;
  }
}

.headerLogo {
  z-index: 999;
  @media screen and (max-width: 450px) {
    width: 160px;
  }
}

.buyJpycButton {
  background-color: #16449A;
  padding: 13.5px 35.5px;
  border-radius: 24px;
  cursor: pointer;
  &:hover {
    background-color: #0f3175;
  }
  > p {
    color: #ffffff;
    margin: 0;
    text-decoration: none;
    font-weight: bold;
  }
  @media screen and (max-width: 450px) {
    padding: 7px 13.5px
  }
}
