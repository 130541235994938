.introduction {
  background: linear-gradient(to bottom, rgba(216, 225, 252, 0.7) 0%, rgba(245, 245, 245, 0) 100%), url('../../../images/topBg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.introductionWrapper {
  display: flex;
  padding-top: 169px;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    padding-top: 100px;
    flex-direction: column;
    align-items: center;
    margin: 0 16px 0 16px;
  }
}

.topLeftBox {
  width: 50%;
  padding-left: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > .introductionTitle {
    margin-bottom: 32px;
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    @media screen and (max-width: 800px) {
      font-size: 24px;
      text-align: center;
    }
  }
  @media screen and (max-width: 800px) {
    align-items: center;
    padding: 0;
    width: 100%;
    > p {
      display: none;
    }
  }
  // max-width: 650px;
}

.jpycTextLogo {
  margin-bottom: 32px;
}

// .introductionTitle {

// }

.subText {
  font-size: 16px;
  margin-bottom: 32px;
  font-weight: 700;
  line-height: 24px;
  color: rgb(1, 10, 30, 0.64);
}

.buyJpyc {
  background-color: #16449A;
  padding: 13.5px 35.5px;
  border-radius: 24px;
  width: 280px;
  color: #ffffff;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: #0f3175;
  }
}

.topBgIcon {
  width: 100%;
  @media screen and (max-width: 800px) {
    display: none;
    opacity: 0;
  }
}

.topJPYCSpSymbol {
  // display: none;
  @media screen and (min-width: 800px) {
    // , screen and (max-width: 600px)
    display: none;
    opacity: 0;
  }
}

.spNone {
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.pcNone {
  text-align: center;
  @media screen and (min-width: 800px) {
    display: none;
  }
}
.spNone.subText {
    max-width: 80%;
}
