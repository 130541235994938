.promotion {
  margin: 16px;
  > .promotionBox {
      max-width: 65em;
      height: 372px;

      /* BOX */

      background: linear-gradient(180deg, #FFFFFF 0%, #E0E4F4 100%);
      box-shadow: 0px 0px 40px #CCE3F9;
      border-radius: 32px;
      margin: 0 auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > a {
        text-decoration: none;
      }
    }
}

.promotionTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 150%;
  color: #16449A;
  margin-bottom: 32px;
  @media screen and (max-width: 800px) {
    font-size: 2.3em;
  }
}

.promotionText {
  font-family: Hiragino Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 150%;
  color:rgb(1, 10, 30, 0.64);
  margin: 0 24px 32px 24px;
}

.promotionButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 74px;

  /* System/JPYC Blue */

  background: #16449A;
  border-radius: 24px;
  &:hover {
    background-color: #0f3175;
  }
}

.promotionButtonText {
  color: #FFFFFF;
  font-family: Hiragino Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 1;
  margin: 0;
  padding: 0;
}
