.howto {
  background-image: url('../../../images/howtoBg.png');
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  margin-bottom: 100px;
}

.howtoBoxWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 100px 80px 100px 80px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding: 0;
    margin: 16px;
  }
}

.howtoMovieBox {
  margin-right: 40px;
  flex: 1.5;
  aspect-ratio: 16 / 9;
  width:100%;
  height:100%;
  max-width: 60%;
  @media screen and (max-width: 800px) {
    margin-right: 0;
    margin-bottom: 60px;
    max-width: 100%;
  }
}

// .movieBox {
//   width: 584px;
//   height: 360px;
//   background: #C4C4C4;
// }

.howtoText {
  color: #16449A;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}

.howtoTypoWrapper {
  flex: 1;
}

.howtoTitle {
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  color: #010A1E;
  margin-bottom: 32px;
}

.howtoSubText {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgb(1, 10, 30, 0.64);
  margin-bottom: 32px;
}

.howtoGoApps {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 150%;
  text-decoration: none;
  color: #16449A;
}
