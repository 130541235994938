.multichain {
  margin-bottom: 100px;
  padding: 0 0 0 80px;
  display: flex;
  justify-content: space-between;
  > .multichainWrapper {
    max-width: 75%;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    padding: 0;
  }
  > .multichainWrapper {
    @media screen and (max-width: 800px) {
      flex-direction: column-reverse;
      padding: 0;
      margin: 50px 16px 0 16px;
      max-width: 100%;
    }
  }
}

.multichainText {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: #16449A;
}

.multichainTitle {
  font-family: Hiragino Sans;
  font-weight: 800;
  font-size: 24px;
  line-height: 150%;
  color: #010A1E;
  margin-bottom: 80px;
}

.chainList {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
}

.chainWrapper {
  margin-bottom: 40px;
  margin-right: 5px;
  > p {
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    margin-bottom: 8px;
    color: rgb(1, 10, 30, 0.64);
  }
  > h4 {
    color: #010A1E;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 16px;
  }
  .chainAddress {
    display: flex;
    align-items: center;
    > p {
      color: #16449A;
      overflow-wrap: anywhere;
    }
  }
}

.coinBannerWrapper {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: space-between;
  overflow: hidden;
  padding: 20px 0 20px 25px;
  @media screen and (max-width: 800px) {
    display:unset;
    position: relative;
    margin: 0;
    padding: 24px;
  }
}

.bannerBox {
  background: linear-gradient(180deg, #FFFFFF 0%, #E0E4F4 100%);
  box-shadow: 0px 0px 40px #CCE3F9;
  border-radius: 8px;
  padding: 20px;
  margin: 10px 0;
  width: fit-content;
  @media screen and (max-width: 800px) {
    padding: 10px 15px;
    height: 25px;
    margin: auto;
  }
}
.bannerBox > img {
  height: inherit;
}

.bannerBox:nth-child(1) {
  margin-right: -55px;
  @media screen and (max-width: 800px) {
    margin: auto 40px auto auto;
  }
}
.bannerBox:nth-child(2) {
  margin-right: 25px;
  @media screen and (max-width: 800px) {
    margin: -20px auto auto 10px;
  }
}
.bannerBox:nth-child(3) {
  margin-right: -55px;
  @media screen and (max-width: 800px) {
    margin: 10px auto auto 30px;
  }
}
.bannerBox:nth-child(4) {
  margin-right: -75px;
  @media screen and (max-width: 800px) {
    margin: -20px 20px auto auto;
  }
}
.bannerBox:nth-child(5) {
  margin-right: 75px;
  @media screen and (max-width: 800px) {
    margin: auto auto auto 55px;
  }
}
.bannerBox:nth-child(6) {
  margin-right: -65px;
  @media screen and (max-width: 800px) {
    margin: -20px 50px auto auto;
  }
}
