.footer {
  background: linear-gradient(to top, rgba(216, 225, 252, 0.7) 0%, rgba(245, 245, 245, 0) 100%), url('../../../images/footerBg.png');
  background-size: 100%;
  width: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.footerWrapper {
  margin: 270px 80px 40px 80px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  @media screen and (max-width: 800px) {
    margin: 160px 16px 16px 16px;
  }
}

.socialGroup {
  display: flex;
  margin-bottom: 40px;
  > a {
    &:hover {
      transform: scale(1.1);
      transition-duration: 0.2s;
    }
  }
}

.jpycLogo {
  margin-bottom: 24px;
}

.socialLogo {
  margin: 0 8px 0 0;
}

.companyAddress {
  margin-bottom: 24px;
  font-family: Hiragino Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: rgb(1, 10, 30, 0.64);
}

.footerListWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 80%;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin-bottom: 40px;
  }
}

.footerList {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
  a {
    text-decoration: none;
    color: rgb(1, 10, 30, 0.64);
  }
  li {
    display: inline;
    list-style-type: none;
    margin-right: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
  li+li {
    border-left: 1px solid #999;
    padding-left: 12px;
    @media screen and (max-width: 800px) {
      border: none;
      padding: 0;
      margin-top: 16px;
    }
  }
}

.copyRight {
  font-size: 14px;
  color: rgb(1, 10, 30, 0.64);
  font-weight: 500;
  text-align: right;
  margin-left: auto;
  @media screen and (max-width: 800px) {
    margin: auto;
  }
}
.icon{              
  width: 32px;    
  vertical-align: middle;
  margin-right:5px;
}           

