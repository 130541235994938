.solution {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }
}

.solutionBoxWrapper {
  padding-left: 80px;
  margin-left: auto;
  @media screen and (max-width: 800px) {
    padding: 0;
    margin: 16px;
  }
}

.personalSolutionText {
  color: #16449A;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  margin-top: 80px;
}

.personalSolutionBgBox1 {
  position: relative;
  width: 20px;
  height: 20px;
  left: 125px;
  top: -45px;
  background: rgba(124, 168, 253, 0.2);
}

.personalSolutionBgBox2 {
  position: relative;
  width: 20px;
  height: 20px;
  left: 136px;
  top: -45px;
  background: rgba(124, 168, 253, 0.2);
}

.personalSolutionTitle {
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 32px;
}

.personalSolutionSubText {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgb(1, 10, 30, 0.64);
}
